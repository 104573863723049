// 排序算法页面
<template>
  <div class="layout-content">
    <el-row>
      <el-button @click="startAll">全部开始</el-button>
    </el-row>
    <el-row v-for="(item, index) in sorts" :key="item.name" type="flex" :gutter="20">
      <el-col :sm="24" :md="12">
        <el-row>
          <el-col>
            <h4>
              <el-row type="flex" justify="space-between">
                <el-col :span="12">{{ item.name }}</el-col>
                <el-col :span="12" style="text-align: right;">
                  <el-input-number size="small" v-model="item.max"></el-input-number>
                  <el-button size="small" @click="restart" :data-index="index + 1">开始</el-button>
                  <el-button>aaa</el-button>
                </el-col>
              </el-row>
            </h4>
          </el-col>
          <el-col>
            <canvas width="600px" height="300px" ref="canvas"></canvas>
          </el-col>
        </el-row>
      </el-col>
      <el-col :sm="24" :md="12">
        <el-row>
          <el-col>
            <h4>
              <el-row type="flex">
                <el-col :span="12"></el-col>
                <el-col :span="12" style="text-align: right;">
                  <!-- <el-button size="small">-</el-button> -->
                </el-col>
              </el-row>
            </h4>
          </el-col>
          <!-- <el-col style="background-color:#ddd; height: 300px; border-radius: 5px;"></el-col> -->
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { store } from "../store";
// import { promises } from "fs";

// let loaded = false;

// 打乱数组
const shuffle = (arr) => {
  for (let i = 0; i < arr.length; i++) {
    const randomIndex = Math.round(Math.random() * (arr.length - 1 - i)) + i;
    [arr[i], arr[randomIndex]] = [arr[randomIndex], arr[i]];
  }
  return arr;
};

// 初始化 canvas
const initCanvas = (canvas) => {
  let canvasHeight = 300 * window.devicePixelRatio;
  let canvasWidth = 600 * window.devicePixelRatio;
  canvas.height = canvasHeight;
  canvas.width = canvasWidth;
  let ctx = canvas.getContext("2d");
  return { ctx, canvasHeight, canvasWidth };
};

// 排序 canvas 类
const SortCanvas = function({
  canvas,
  padding = 2,
  // speed = 5,
  min,
  max,
  calc,
}) {
  let { ctx, canvasHeight, canvasWidth } = initCanvas(canvas);
  this.defaultColor = "#aaa";
  this.highlightColor = "#299";
  this.overColor = "#0aa";
  this.highlight = {};
  this.min = min;
  this.max = max;
  this.calc = calc;
  this.ctx = ctx;
  this.canvasHeight = canvasHeight;
  this.canvasWidth = canvasWidth;
  this.padding = padding;
  this.calced = false;
  this.arr = randomArray(this.min, this.max);
  this.Update();
};

SortCanvas.prototype.SetMax = function(max) {
  this.max = max || this.max;
  this.arr = randomArray(this.min, this.max);
};

SortCanvas.prototype.Start = async function() {
  // 如果已经排序过了，则重新生成一个随机数组
  if (this.calced) {
    this.calced = false;
    this.arr = randomArray(this.min, this.max);
  }
  this.Update();
  if (this.calc) {
    this.arr = await this.calc(this.arr, this);
    this.calced = true;
  }
};
// 更新 canvas
SortCanvas.prototype.Update = function() {
  let arr = this.arr;
  let height = this.canvasHeight / this.max;
  let width = this.canvasWidth / arr.length - this.padding;
  this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
  for (let i = 0; i < arr.length; i++) {
    let h = arr[i] * height;
    let x = i * (width + this.padding);
    let y = this.canvasHeight - h;
    if (i in this.highlight) {
      this.ctx.fillStyle = this.color;
    } else {
      this.ctx.fillStyle = this.defaultColor;
    }
    this.ctx.fillRect(x, y, width, h);
    this.ctx.fillStyle = this.defaultColor;
  }
};
// 延时绘图
SortCanvas.prototype.DrawCanvas = async function(speed = this.speed) {
  this.Update(this.arr);
  await sleep(speed);
};
// 高亮块
SortCanvas.prototype.Highlight = function(index, color = this.highlightColor) {
  this.highlight[index] = true;
  this.color = color;
};
// 清除高亮
SortCanvas.prototype.ClearHighlight = function() {
  this.highlight = {};
  this.color = this.defaultColor;
  this.Update();
};
// 结束时顺序高亮动画
SortCanvas.prototype.OverHighlight = async function(speed = 0) {
  this.ClearHighlight();
  for (let i = 0; i < this.arr.length; i++) {
    this.Highlight(i, this.overColor);
    await this.DrawCanvas(speed);
  }
};

// 获取随机数组
const randomArray = (min, max) => {
  let arr = [];
  for (let i = min; i <= max; i++) {
    arr.push(i);
  }
  return shuffle(arr);
};

const sleep = (milliseconds) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      resolve();
    }, milliseconds);
  });
};

// 冒泡
const maopao = async (arr, sortCanvas) => {
  let startTime = Date.now();
  let temp = 0;
  for (let j = 1; j < arr.length; j++) {
    temp = 0;
    for (let i = 0; i < arr.length; i++) {
      sortCanvas.ClearHighlight();
      if (i + 1 < arr.length) {
        sortCanvas.Highlight(i);
        sortCanvas.Highlight(i + 1);
        if (arr[i] > arr[i + 1]) {
          temp = arr[i];
          arr[i] = arr[i + 1];
          arr[i + 1] = temp;
        }
      }
      await sortCanvas.DrawCanvas();
    }
    if (temp == 0) {
      break;
    }
  }
  console.log("over 冒泡排序", Date.now() - startTime);
  sortCanvas.OverHighlight();
  return arr;
};

// 选择排序
const xuanze = async (arr, sortCanvas) => {
  let startTime = Date.now();
  for (let j = 0; j < arr.length; j++) {
    let temp = 0;
    let minIndex = j;
    for (let i = j; i < arr.length; i++) {
      sortCanvas.ClearHighlight();
      sortCanvas.Highlight(minIndex);
      sortCanvas.Highlight(i);
      if (arr[minIndex] > arr[i]) {
        temp = arr[minIndex];
        arr[minIndex] = arr[i];
        arr[i] = temp;
      }

      await sortCanvas.DrawCanvas();
    }
    if (temp == 0) {
      break;
    }
  }

  console.log("over 选择排序", Date.now() - startTime);
  sortCanvas.OverHighlight();
  return arr;
};

// 快速排序
const kuaisu = async (arr, sortCanvas) => {
  const quickSort = async (arr, low, high) => {
    let i = low;
    let j = high;
    let pivot = arr[low];
    if (i >= j) {
      return;
    }
    while (i != j) {
      sortCanvas.ClearHighlight();
      while (i < j && arr[j] >= pivot) j--;
      if (i < j) arr[i] = arr[j];
      while (i < j && arr[i] <= pivot) i++;
      if (i < j) arr[j] = arr[i];

      sortCanvas.Highlight(i);
      sortCanvas.Highlight(j);
      await sortCanvas.DrawCanvas();
    }
    arr[i] = pivot;
    await quickSort(arr, low, i - 1);
    await quickSort(arr, i + 1, high);
  };

  let startTime = Date.now();
  await quickSort(arr, 0, arr.length - 1);

  console.log("over 快速排序", Date.now() - startTime);
  sortCanvas.OverHighlight();
  return arr;
};

// 希尔排序 shell sort（缩小增量）
const shell = async (arr, sortCanvas) => {
  let startTime = Date.now();
  let len = arr.length;
  let temp = 0;
  let gap = Math.floor(len / 2);
  while (gap > 0) {
    for (let i = gap; i < len; i++) {
      temp = arr[i];
      let preIndex = i - gap;

      sortCanvas.ClearHighlight();
      sortCanvas.Highlight(i);
      while (preIndex >= 0 && arr[preIndex] > temp) {
        arr[preIndex + gap] = arr[preIndex];
        preIndex -= gap;
      }
      arr[preIndex + gap] = temp;
      sortCanvas.Highlight(preIndex + gap);
      await sortCanvas.DrawCanvas();
    }
    gap = Math.floor(gap / 2);
  }

  console.log("over 希尔排序", Date.now() - startTime);
  sortCanvas.OverHighlight();
  return arr;
};

// canvas map
let canvasMap = {};

export default {
  data: () => {
    return {
      sorts: [
        { name: "冒泡排序", max: 100, func: maopao },
        { name: "选择排序", max: 100, func: xuanze },
        { name: "快速排序", max: 100, func: kuaisu },
        { name: "希尔排序", max: 100, func: shell },
      ],
    };
  },
  mounted() {
    // 切换主题
    store.setTheme("light");
    for (let i = 0; i < this.sorts.length; i++) {
      let canvas = new SortCanvas({
        canvas: this.$refs.canvas[i],
        padding: 0,
        speed: 0,
        min: 1,
        max: this.sorts[i].max,
        calc: this.sorts[i].func,
      });

      canvasMap[i + 1] = canvas;
    }
  },
  methods: {
    restart(e) {
      // element ui 点击按钮里的 span 时，获取不到按钮上的 dataset
      let element = e.target;
      // if (element.tagName == "SPAN") {
      //   element = element.parentNode;
      // }
      let index = element.dataset.index;

      let info = this.sorts[index - 1];
      // console.log(info);
      let sortCanvas = canvasMap[index];
      if (!sortCanvas) {
        console.log("sort canvas not ready");
        console.log(canvasMap);
        return;
      }
      sortCanvas.SetMax(info.max);
      sortCanvas.Start();
    },
    startAll() {
      for (let i in canvasMap) {
        let canvas = canvasMap[i];
        let info = this.sorts[i - 1];
        canvas.SetMax(info.max);
        canvas.Start();
      }
    },
  },
};
</script>

<style lang="less" slot-scope>
canvas {
  width: 100%;
  height: 300px;
  background-color: #ddd;
  // border-radius: 10px;
}
</style>
